$(document).foundation();


$(document).ready(function(){

    var mustBeLoggedIn = $('[data-remodal-id=must-be-logged-in]').remodal();

    $('[data-login-required]').click(function(e){

        if(!loggedIn){

            e.stopImmediatePropagation();

            console.log("Login Action Cancelled.");

            mustBeLoggedIn.open();

        }

    });

    $('.mobile-toggle').click(function () {
       $('.mobile-toggle').toggleClass('active');
       $('.mobile-menu').toggle();

    });

    $('[data-action]').click(function(e){

        var el = $(e.currentTarget);
        var data = el.attr('data-action');

        actionData = data.split('|');

        var action = actionData[0];
        var objectID = actionData[1];
        var objectName = actionData[2];
        url = '/perform-action';


        $.ajax({
            type: "POST",
            url: url,
            data: {action:action,objectID:objectID,_token:csrfToken},
            success: function(){
                console.log("Action performed: ", action,objectID);
            }
        });

        if(action == 'ViewFont') {
            ga('send','event','Font', 'Downloaded or Viewed', objectName, 1)
        }
    });


});